import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/content/voice-and-tone/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{props.pageContext.frontmatter.description}</p>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`What are voice and tone?`}</h2>
        <h4>{`Voice is the foundation of every written or spoken communication.`}</h4>
        <p>{`Voice makes us `}{`—`}{` Twilio `}{`—`}{` identifiable, consistent, and familiar.`}</p>
        <h4>{`Tone is how you modify what you say to convey emotion or intent.`}</h4>
        <p>{`Tone enables you to choose words, structures, punctuation, or syntax appropriate to the scenario.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Voice or tone?</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Think of your best friend. You can recognize their voice &mdash; that doesn't change. But how they speak in a job
    interview is different from how they chat with you at the weekend. They <strong>sound</strong> the same &mdash;
    that’s voice. But they change <strong>how</strong> they express themselves &mdash; that’s tone.
  </CalloutText>
        </Callout>
        <hr></hr>
        <h3>{`Twilio voice and tone`}</h3>
        <h4>{`The Twilio Voice is how we sound in writing or out loud.`}</h4>
        <p>{`The characteristics of our voice don’t change. Twilio always sounds`}</p>
        <ul>
          <li parentName="ul">{`Human`}</li>
          <li parentName="ul">{`Accurate`}</li>
          <li parentName="ul">{`Authentic`}</li>
        </ul>
        <hr></hr>
        <h4>{`Tone has four parts`}</h4>
        <p>{`The four qualities of tone are as follows. The exact mix changes to meet the user’s needs and situation.`}</p>
        <ul>
          <li parentName="ul">{`Positive`}</li>
          <li parentName="ul">{`Conversational`}</li>
          <li parentName="ul">{`Encouraging`}</li>
          <li parentName="ul">{`Knowledgeable`}</li>
        </ul>
        <h4>{`Human = write with the user in mind`}</h4>
        <p>{`Users succeed when they achieve their goal efficiently and have a positive experience.
Create content that appeals to both their head and heart `}{`—`}{` content that you'd want to read.`}</p>
        <ul>
          <li parentName="ul">{`Reduce cognitive load by using common words in simple, short structures.`}</li>
          <li parentName="ul">{`Be empathetic and consider the user's situation and emotional state.`}</li>
          <li parentName="ul">{`Offer appropriate reactions, just as you would face-to-face.`}</li>
        </ul>
        <h4>{`Accurate = write with precision`}</h4>
        <p>{`Users need our content to be accurate.
They rely on the words we use to help them achieve their goal.
A user should never be surprised by what content means.`}</p>
        <ul>
          <li parentName="ul">{`Use consistent language for features and products.`}</li>
          <li parentName="ul">{`Give the user all the information they need to make a decision.`}</li>
          <li parentName="ul">{`Remember, you are not the user.`}</li>
        </ul>
        <h4>{`Authentic = write transparently`}</h4>
        <p>{`Users need our content to be truthful and evidence-based.
They rely on our products to do what they say.
Being evasive or exaggerating isn’t useful and impacts the user’s trust in our products.`}</p>
        <ul>
          <li parentName="ul">{`Be clear about what is and isn’t possible, particularly with beta or pilot features.`}</li>
          <li parentName="ul">{`Never suggest or claim that the product does something it can’t deliver.`}</li>
          <li parentName="ul">{`Test the product rigorously so you can be confident and precise about its functions.`}</li>
        </ul>
        <hr></hr>
        <h2>{`Tone`}</h2>
        <p>{`Tone is used to modify how we communicate, according to the scenario and the user’s situation.
Think of tone as a stabilizer tool.`}</p>
        <p>{`We want our users to feel`}</p>
        <ul>
          <li parentName="ul">{`Confident`}</li>
          <li parentName="ul">{`At ease`}</li>
          <li parentName="ul">{`Empowered`}</li>
          <li parentName="ul">{`Reassured`}</li>
        </ul>
        <p>{`To do that, we use a combination of tonal qualities`}</p>
        <ul>
          <li parentName="ul">{`Positive`}</li>
          <li parentName="ul">{`Conversational`}</li>
          <li parentName="ul">{`Encouraging`}</li>
          <li parentName="ul">{`Knowledgeable`}</li>
        </ul>
        <hr></hr>
        <h3>{`Tonal qualities`}</h3>
        <h4>{`Positive`}</h4>
        <p>{`You're task-focused but warm. When the user wins, you recognize their success genuinely.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` be engaging, efficient, and forward-looking.`}</li>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` front-load messages with the reason (why) then the action (how.)`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`To get started, add a user`}</em></li>
              <li parentName="ul"><em parentName="li">{`Congratulations, you sent your first campaign email!`}</em></li>
            </ul></li>
        </ul>
        <p>{`Being over-enthusiastic all the time can sound insincere. Choose moments of infrequent success.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` be annoying, hyperbolic, or misleading.`}</li>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` add empty phrases or punctuation to try to sound positive.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`First up, add a user, then we can get going!`}</em></li>
            </ul></li>
        </ul>
        <h4>{`Conversational`}</h4>
        <p>{`Speak to the user as you would in person. Imagine you're on friendly, but professional terms.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` be friendly, approachable, and straightforward.`}</li>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` use common contractions (you’ll, we’re, let’s.)`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`You're not logged in`}</em></li>
            </ul></li>
        </ul>
        <p>{`You aren't jokey or casual with the user. Remember, the user has a goal to complete.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` be colloquial or over-familiar.`}</li>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` use slang or idioms. These can exclude users and obscure meaning.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Head over to Studio`}</em></li>
              <li parentName="ul"><em parentName="li">{`Oops, looks like you're a few characters short of a password`}</em></li>
            </ul></li>
        </ul>
        <p>{`Write as much copy as you need to convey the message in a conversational tone.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Avoid`}</strong>{` sounding robotic or sacrificing meaning to save space. Shorter is not always better or clearer.`}</li>
          <li parentName="ul"><strong parentName="li">{`Avoid`}</strong>{` prioritizing space or design over comprehension or conversational language.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Create`}</em></li>
            </ul></li>
          <li parentName="ul">{`Instead, choose the words that clearly communicate what an interaction does.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Create Agent profile`}</em></li>
            </ul></li>
        </ul>
        <h4>{`Encouraging`}</h4>
        <p>{`Show empathy, advise, and guide. Show the user how to succeed to give them confidence.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` be helpful, reassuring, and supportive.`}</li>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` include a `}<em parentName="li">{`please`}</em>{` if the user is making additional effort.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`To apply changes, please reload the page`}</em></li>
              <li parentName="ul"><em parentName="li">{`Change an Agent's skill level in Agent Profile`}</em></li>
            </ul></li>
        </ul>
        <p>{`Users need support and reassurance without judgement.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` be patronizing or sound impatient.`}</li>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` precede an instruction with `}<em parentName="li">{`just`}</em>{` or frame it as being easy or simple.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`To apply changes, just reload the page`}</em></li>
              <li parentName="ul"><em parentName="li">{`Change an Agent's skill level easily in Agent Profile`}</em></li>
            </ul></li>
        </ul>
        <h4>{`Knowledgeable`}</h4>
        <p>{`Empower the user by sharing information. Show them the edges of the flow for context.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` be informative, guiding, and clear.`}</li>
          <li parentName="ul"><strong parentName="li">{`Do`}</strong>{` signpost what’s next to enable discovery.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`To see available networks, select a country`}</em></li>
            </ul></li>
        </ul>
        <p>{`Avoid surprising the user with another step and don't assume they know as much as you.`}</p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` be dictatorial, superior, or know-it-all.`}</li>
          <li parentName="ul"><strong parentName="li">{`Don't`}</strong>{` make the user guess what an interaction will do.`}<ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Select country`}</em></li>
            </ul></li>
        </ul>
        <hr></hr>
        <h2>{`Finding tone that works`}</h2>
        <p>{`Identify the user, their place in the experience, what they need, and their emotional state. Put yourself in their shoes.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": "left"
              }}>{`Interaction`}</th>
              <th parentName="tr" {...{
                "align": "left"
              }}>{`Context`}</th>
              <th parentName="tr" {...{
                "align": "left"
              }}>{`User need`}</th>
              <th parentName="tr" {...{
                "align": "left"
              }}>{`Approach`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Neutral`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`A repeated interaction (Login screen, confirmation message)`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Familiarity and recognition. Efficiency, nothing unusual, and no friction`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Conversational and positive. Be efficient, approachable, and unobtrusive`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Positive`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`A high point (Completion of a complex flow, first-time success with a process)`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Confirmation of success. A positive affirmation and clear next step`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Positive and encouraging. Be congratulatory and forward-looking`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Negative`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`An error or problem (Access denied, system failure)`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Resolution and reassurance. A clear solution and reassurance`}</td>
              <td parentName="tr" {...{
                "align": "left"
              }}>{`Knowledgeable and encouraging. Be clear, informative, and calm`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`Tone balances the interaction, leaving the user in a net-positive place emotionally.`}</p>
        <p>{`If in doubt, aim to sound like a friendly specialist. Be the voice of someone you'd want to come back to for support or guidance.`}</p>
        <p>{`Every interaction deserves consideration. If you can write in the Twilio Voice and use appropriate tonal qualities, your content will have a strong, user-focused foundation.`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      